import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent} from './theme/layout/auth/auth.component';
import { SessionGuardService as AuthGuard} from './service/session-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'greenmain', loadChildren: () => import('../app/sockcho/greenmain/greenmain.module').then(module => module.GreenMainModule)
  },
  {
    path: 'greenselect', loadChildren: () => import('../app/sockcho/greenselect/greenselect.module').then(module => module.GreenSelectModule)
  },
  {
    path: 'greenoffset', loadChildren: () => import('../app/sockcho/greenoffset/greenoffset.module').then(module => module.GreenOffsetModule)
  },
  {
    path: 'greenregion', loadChildren: () => import('../app/sockcho/greenregion/greenregion.module').then(module => module.GreenRegionModule)
  },
  {
    path: 'greenmethod', loadChildren: () => import('../app/sockcho/greenmethod/greenmethod.module').then(module => module.GreenMethodModule)
  },
  {
    path: 'greenparticipate', loadChildren: () => import('../app/sockcho/greenparticipate/greenparticipate.module').then(module => module.GreenParticipateModule)
  },
  {
    path: 'greenpay', loadChildren: () => import('../app/sockcho/greenpay/greenpay.module').then(module => module.GreenPayModule)
  },
  {
    path: 'greenpaysuccess', loadChildren: () => import('../app/sockcho/greenpaysuccess/greenpaysuccess.module').then(module => module.GreenPaySuccessModule)
  },
  {
    path: 'greenthankyou', loadChildren: () => import('../app/sockcho/greenthankyou/greenthankyou.module').then(module => module.GreenThankyouModule)
  },
  {
    path: 'greenlicense', loadChildren: () => import('../app/sockcho/greenlicense/greenlicense.module').then(module => module.GreenLicenseModule)
  },
  {
    path: 'greencheck', loadChildren: () => import('../app/sockcho/greencheck/greencheck.module').then(module => module.GreenCheckModule)
  },
  {
    path: 'greenquestion', loadChildren: () => import('../app/sockcho/greenquestion/greenquestion.module').then(module => module.GreenQuestionModule)
  }
 ];



@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }




